@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
}

@layer components{
    .side-paddings{
        @apply px-6 md:px-8 xl:px-12 2xl:px-16
    }
    .side-paddings-except-mobile{
        @apply md:px-8 xl:px-12 2xl:px-16
    }
}


@font-face {
    font-family: 'koverwatch';
    src: url('./fonts/koverwatch.woff2');
}

@font-face {
    font-family: 'OSKARI G2';
    font-weight: 400;
    src: url('./fonts/OSKARI G2 REGULAR.woff2');
}

@font-face {
    font-family: 'OSKARI G2';
    font-weight: bold;
    src: url('./fonts/OSKARI G2 BOLD.woff2');
}

@font-face {
    font-family: 'OSKARI G2';
    font-weight: 500;
    src: url('./fonts/OSKARI G2 MEDIUM.woff2');
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    width: auto !important;
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.react-simple-star-rating svg {
    display: inline;
}


.multi-range-slider .bar-inner{
    box-shadow: none !important;
    border:none !important;
}

.multi-range-slider{
    border:none !important;
    box-shadow: none !important;
}

.multi-range-slider .bar-right{
    box-shadow: none !important;
    background-color:#232527 !important;
}

.multi-range-slider .bar-left{
    box-shadow: none !important;
    background-color:#232527 !important;
}

.multi-range-slider .thumb::before{
    box-shadow: none !important;
}

.multi-range-slider .thumb .caption *{
    display: none;
}

.ch-2{
    width:2ch;
}

/* decrease font size on mobile screen */

@media screen and (max-width:1280px) {
    html{
        font-size: 15px;
    }
}
@media screen and (max-width:960px) {
    html {
        font-size: 13px;
    }
}




